import React, { ReactElement } from "react";
import Form from "@components/shared/form";
import styles from "./styles.module.scss";
import { useDomainContext } from "@hooks/useDomainContext";
import { useRouter } from "next/router";
import { finalShowForm } from "@lib/sharedUtils";
import TextBlockOffer from "@components/shared/textBlockOffer/TextBlockOffer";

export default function MainBanner(): ReactElement {
    const router = useRouter();
    const { domain } = useDomainContext();
    const isFormDisplayed = finalShowForm(
        router.query.sl as string,
        domain.showForm,
        domain.defaultOffers,
    );
    return (
        <>
            {isFormDisplayed ? (
                <div className={styles["main-banner"]}>
                    <div
                        className={`block lg:flex ${styles["main-banner-inner"]}`}
                    >
                        <div>
                            <Form
                                classes={{
                                    formClassName: styles["form"],
                                    stepTitleClassName: styles["step-title"],
                                    formProgressbarClassName:
                                        styles["progress"],
                                    stepClassName: styles["step-block"],
                                    stepDescriptionClassName:
                                        styles["step-description"],
                                    formButtonClassName: styles["form-button"],
                                    formFieldClassName: styles["form-field"],
                                    formStepInnerClassName:
                                        styles["steps-inner"],
                                    fieldsClassName: {
                                        radio: styles["radio-field"],
                                    },
                                    fieldsWrapperClassName:
                                        styles["fields-wrapper"],
                                    tcpaClassName: styles["tcpa"],
                                    labelAsTitleClassName:
                                        styles["label-as-step-title"],
                                }}
                                colors={{
                                    progressBar: "#b1b1b1",
                                    primaryColor: "#ffce85",
                                }}
                                backButton={{
                                    class: styles["back-button"],
                                    label: "Back",
                                }}
                                showProgress={true}
                                preventWindowClose
                            />
                        </div>
                    </div>
                </div>
            ) : (
                <TextBlockOffer />
            )}
        </>
    );
}
